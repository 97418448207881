import {
  Box,
  Button,
  DarkMode,
  Flex,
  Image,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

const Login = ({ setloggedIn }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onChange = (e) => {
    if (e.target.value === "Eiterstift" && setloggedIn) {
      setloggedIn(true);
    }
  };

  return (
    <>
      <Box>
        <Input
          onChange={onChange}
          bgColor={"whiteAlpha.900"}
          type={"password"}
        />
      </Box>
      <Button
        onClick={onOpen}
        mr={2}
        mb={2}
        bottom="0"
        right={0}
        position={"absolute"}
        size="sm"
        bgColor={"whiteAlpha.900"}
      >
        ?
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Image alt="" src={require("./assets/header-pickel.jpeg")} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default Login;
