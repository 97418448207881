import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { INFURA_ID } from "./constants";

const POLLING_INTERVAL = 12000;
const RPC_URLS = {
  1: `https://mainnet.infura.io/v3/${INFURA_ID}`,
  4: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
  137: `https://polygon-mainnet.infura.io/v3/${INFURA_ID}`,
};

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1], 4: RPC_URLS[4] },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});
