import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useRef } from "react";
import { useState, useCallback, useMemo } from "react";

import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { useEthers } from "@usedapp/core";
import MEIN_MAUS from "./lib/MeineMaus.json"
import { ethers, utils } from "ethers";
import {PRICE} from './lib/constants'
import ConnectWallet from "./ConnectButton";

const MyVideo = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;

const MyButton = ({ children, onClick }) => (
  <Button
    onClick={onClick}
    mx={0.5}
    color="whiteAlpha.900"
    size="lg"
    bgColor={"blackAlpha.900"}
  >
    {children}
  </Button>
);

const Intro = () => {
  const [on, seton] = useState(false);
  const [video, setVideo] = useState("");
  const { width, height } = useWindowSize()

  const [opened, setOpened] = useState({
    maus1: false,
    maus2: false,
    maus3: false,
    maus4: false,
    maus5: false,
    maus6: false,
    maus7: false,
    maus8: false,
    maus9: false,
    maus10: false,
    maus11: false,
    maus12: false,
    maus13: false,
  });

  const suprise = useMemo(() => {
    let re = true;
    Object.values(opened).forEach(item => {
      if (!item) {
        re = false;
      }
    })
    return re
  }, [opened])

  const { account, active, library, activateBrowserWallet } = useEthers();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { isOpen: isOpenMint, onOpen:onOpenMint, onClose: onCloseMint } = useDisclosure();

  const url = (maus) => `https://firebasestorage.googleapis.com/v0/b/mausbday.appspot.com/o/${maus}.mp4?alt=media`

  const mint = useCallback(async () => {
    if (!active || !account) {
      activateBrowserWallet();
    } else {
      const signer = library.getSigner();
      const contract = new ethers.Contract(
        "0x220619008C497C79D7c4AE24784521Aa723B796C",
        MEIN_MAUS.abi,
        signer
      );
      try {
        const gasEstimate = await contract.estimateGas.mint(`1`, {
          value: utils.parseEther((1 * PRICE).toFixed(4)),
        });
        const gas = parseFloat(utils.formatUnits(gasEstimate, "wei"));
        contract
          .mint(`1`, {
            gasLimit: Math.round(gas * 1.05),
            value: utils.parseEther((1 * PRICE).toFixed(4)),
          }).then(suc => {
            window.open("https://opensea.io/collection/meine-maus-bday")
          })
          
      } catch (e) {
        console.log(e);
      }
    }
  }, [account, active, activateBrowserWallet, library]);

  const mute = () => {
    var video = document.getElementById("myVideo");
    seton((prev) => !prev);
    video.muted = on;
    video.play();
  };

  const buttonClick = (name) => {
    setOpened(prev => ({
      ...prev,
      [name]: true
    }))
    setVideo(name);
    onOpen();

  };

  return (
    <>
      <Flex w="100%" h="100%" alignItems={"center"} justifyContent="center">
      <Confetti
      width={width}
      height={height}
    />
        <Box>
          <Box
            opacity={0.4}
            zIndex={2}
            bgColor={"black"}
            pos={"fixed"}
            right={0}
            bottom={0}
            minW="100%"
            minH={"100%"}
          />
          <MyVideo autoPlay muted loop id="myVideo">
            <source
              src={url('maus-bday-intro')}
              type="video/mp4"
            />
          </MyVideo>
        </Box>

        <Box zIndex={3}>
          <Box mb={6}>
            <MyButton onClick={() => buttonClick("maus1")}>H</MyButton>
            <MyButton onClick={() => buttonClick("maus2")}>A</MyButton>
            <MyButton onClick={() => buttonClick("maus3")}>P</MyButton>
            <MyButton onClick={() => buttonClick("maus4")}>P</MyButton>
            <MyButton onClick={() => buttonClick("maus5")}>Y</MyButton>
          </Box>
          <Box mb={6}>
            <MyButton onClick={() => buttonClick("maus6")}>B</MyButton>
            <MyButton onClick={() => buttonClick("maus7")}>I</MyButton>
            <MyButton onClick={() => buttonClick("maus8")}>R</MyButton>
            <MyButton onClick={() => buttonClick("maus9")}>T</MyButton>
            <MyButton onClick={() => buttonClick("maus10")}>H</MyButton>
            <MyButton onClick={() => buttonClick("maus11")}>D</MyButton>
            <MyButton onClick={() => buttonClick("maus12")}>A</MyButton>
            <MyButton onClick={() => buttonClick("maus13")}>Y</MyButton>
          </Box>
          <Box>
          {suprise && <MyButton onClick={onOpenMint}>SUPRISE</MyButton>}
        </Box>
        </Box>
     
        <Button
          onClick={mute}
          mr={2}
          mb={2}
          bottom="0"
          right={0}
          position={"absolute"}
          size="sm"
          bgColor={"whiteAlpha.900"}
          zIndex={3}
        >
          🔊
        </Button>
        {
          <Modal  zIndex={5} isOpen={isOpenMint} onClose={onCloseMint}>
          <ModalOverlay />
          <ModalContent p={10} color={'white'} bgColor='black' minW={500}>
            <Text mb={5} textAlign={'center'}>HAB DICH LIEB</Text>
            <ConnectWallet withAddress={true} />
            {account && <Button onClick={mint}>MINT</Button>}
          </ModalContent>
        </Modal>
        }
        {video.length > 0 && (
          <Modal zIndex={5} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={500}>
              <video autoPlay loop>
                <source
                  src={url(video)}
                  type="video/mp4"
                />
              </video>
            </ModalContent>
          </Modal>
        )}
      </Flex>
    </>
  );
};

export default Intro;
