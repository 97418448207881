import './App.css';
import { useState } from 'react';
import Login from './Login';
import Intro from './Intro';
import { DarkMode, Flex } from '@chakra-ui/react';

function App() {
  const [loggedIn, setloggedIn] = useState(false);

  return (
    <div className="App">
      <DarkMode>
     <Flex bgColor={'blackAlpha.900'} w='100%' h='100vh' justifyContent={'center'} alignItems='center'>
        {!loggedIn && <Login setloggedIn={setloggedIn} />}
        {loggedIn && <Intro />}
     </Flex>
     </DarkMode>
    </div>
  );
}

export default App;
