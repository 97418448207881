import {
  Button,
  ChakraProvider,
  Flex,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useEthers, useLookupAddress } from "@usedapp/core";
import { walletconnect } from "./lib/connectors";


function ConnectWallet({ setaddressShort, withAddress = false }) {
  const [modalopen, setmodalopen] = useState(false);
  const { activate, activateBrowserWallet, deactivate, account } = useEthers();

  const buttonClick = useCallback(() => {
    if (account) {
      deactivate();
      if (setaddressShort) {
        setaddressShort("");
      }
    } else {
      setmodalopen(true);
    }
  }, [account, deactivate, setaddressShort]);

  const buttonText = useMemo(
    () => (account ? "Disconnect Wallet" : "Connect Wallet"),
    [account]
  );

  const onClose = () => setmodalopen(false);

  const ens = useLookupAddress();
  const displayAddress = useMemo(() => {
    if (ens && ens.indexOf("0x") < 0) {
      return ens;
    }
    if (account) {
      return `${account.substr(0, 6)}...${account.substr(-4)}`;
    }
    return null;
  }, [account, ens]);

  return (
    <ChakraProvider>
      <Button
        bgColor={"rgba(255,255,255,0.1)"}
        border="4px solid white"
        px={[5, 10]}
        _hover={{ bg: "#FF85DA;" }}
        borderRadius={5}
        onClick={buttonClick}
      >
        <Flex flexDir={"column"} padding="3%" fontWeight={400} color="white">
          <span>{buttonText}</span>
          {withAddress && displayAddress && (
            <Text fontSize={"0.6em"}>{displayAddress}</Text>
          )}
        </Flex>
      </Button>
      <Modal borderRadius={15} isOpen={modalopen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent backgroundColor="#FF85DA" color="white" padding="1.5%">
          <Flex flexDir={"column"} padding="1% 5%">
            <Button
              margin="1%"
              padding="1%"
              _hover={{ bg: "#000" }}
              backgroundColor="#141414"
              onClick={() => {
                activateBrowserWallet();
                setmodalopen(false);
              }}
            >
              <Flex
                height={"100%"}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <div>MetaMask</div>
                <Image
                  src="https://firebasestorage.googleapis.com/v0/b/buttheads-production.appspot.com/o/logo-metamask.png?alt=media&token=cfe421e9-bc3a-43dd-9fde-ef0ff5fd089b"
                  alt="MetaMask"
                  height={"100%"}
                  w={"auto"}
                />
              </Flex>
            </Button>
            <Button
              margin="1%"
              padding="1%"
              _hover={{ bg: "#000" }}
              backgroundColor="#141414"
              onClick={() => {
                activate(walletconnect);
                setmodalopen(false);
              }}
            >
              <Flex
                height={"100%"}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <div>WalletConnect</div>
                <Image
                  src="https://firebasestorage.googleapis.com/v0/b/buttheads-production.appspot.com/o/walletconnect-logo.svg?alt=media&token=c21f3c9b-5543-4fa2-b3a1-862477f95ba6"
                  alt="WalletConnect"
                  height={"100%"}
                  w={"auto"}
                />
              </Flex>
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
}

export default ConnectWallet;
